figcaption {
  font-size: 8px;
  padding: 2px;
  max-width: fit-content;
  margin-top: -24px;
  margin-left: auto;
  margin-right: 3px;
}

figcaption a {
  background-color: rgba(255, 255, 255, 0.72);
  border-radius: 10px;
  padding: 4px 5px 0px 5px;
  margin: 2px;
}

.card-loading {
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
  margin-right: -2px;
}

.highlight-container {
  min-height: 75px;
  min-width: 150px;
}

.plant-card .MuiCardHeader-title {
  max-width: 325px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
}

.plant-card .MuiCardHeader-root {
  padding-left: 10px;
  padding-bottom: 0px;
}

.plant-card .MuiCardContent-root {
  padding-top: 10px;
}

.plant-card.selected {
  background-color: #a9ff89;
  outline-style:solid ;
  outline-color: #a9ff89;
  outline-width: 4px;
}

.plant-image-container {
  position: relative;
}

button.add-plant-button {
  background-color: #4caf50;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  border: solid;
  border-width: 2px;
  border-color: white;
  border-style: solid;
}

button:hover.add-plant-button {
  background-color: #4caf50;
  color: white;
}
