body {
  font-family: Arial, sans-serif;
  margin: 0;
}

.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
