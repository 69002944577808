@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  height: 575px;
  min-height: 575px;
  max-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.spinner img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 128px;
  height: 128px;
  animation: spin 2.5s linear infinite;
}
