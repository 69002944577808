.nav-container {
  padding-bottom: 0;
}

.nav-container a {
  color: white;
}

.icon {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 690px) {
  #slogan {
    display: none;
  }
}
