.garden-summary {
    max-width: 800px;
    margin: auto;
    padding: 1px 20px 20px 20px;
    margin-bottom: 30px;
}

.garden-summary div {
    display: flex;
    justify-content: center;
}

.garden-summary h3 {
    display: flex;
    justify-content: center;
}

#share-garden-url:disabled {
    color: rgb(0, 0, 0, 0.87);
}